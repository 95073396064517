<template>
    <div>
        <jy-dialog type="table" title="查看明细" :visible.sync="dialogVisible" custom-class="dialog_table" :width="global.dialogTabelWidth">
            <jy-query ref="checkForm" :model="formInline">
                <jy-query-item label="机构:" prop="orgName" :span="1.3">
                    <div @click="selectInstitutions">
                        <el-input placeholder="请选择" v-model="formInline.orgName"></el-input>
                    </div>
                </jy-query-item>
                <jy-query-item label="线路:" prop="routeId" :span="1.3">
                    <!-- <div @click="xlselectInstitutions">
                        <el-input placeholder="请选择" v-model="formInline.routeName"></el-input>
                    </div> -->
                    <el-select v-model="formInline.routeId" placeholder="请选择线路">
                        <el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId">
                        </el-option>
                    </el-select>
                </jy-query-item>
                <jy-query-item label="车牌号:" prop="vehicleNo" :span="1.3">
                    <el-input v-model="formInline.vehicleNo"></el-input>
                </jy-query-item>
                <template slot="rightCol">
                    <el-button type="primary" @click="oncheck">查询</el-button>
                    <el-button type="primary" @click="resetForm('checkForm')">重置</el-button>
                </template>
            </jy-query>
            <jy-table :data="lists">
                <jy-table-column type="index" width="60" label="序号"> </jy-table-column>
                <jy-table-column prop="vehicleNo" label="车牌号" min-width="120"> </jy-table-column>
                <jy-table-column prop="vin" label="VIN" min-width="120"> </jy-table-column>
                <jy-table-column prop="routeName" label="所属线路" min-width="120"> </jy-table-column>
                <jy-table-column prop="orgName" label="所属机构" min-width="120"> </jy-table-column>
                <jy-table-column prop="inStationTime" label="进场时间" min-width="120"> </jy-table-column>
                <jy-table-column prop="outStationTime" label="出场时间" min-width="120"> </jy-table-column>
            </jy-table>
            <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
            </jy-pagination>
        </jy-dialog>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/system/org/queryOrgTree" title="选择机构"></institutions-tree>
        <xlinstitutions-tree ref="xlinstitutions" @addInstitutions="xladdInstitutions" url="/waybillOperate/getTree" title="选择线路" :defaultProps="props"></xlinstitutions-tree>
    </div>
</template>
<script>
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
import xlinstitutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
export default {
    data() {
        return {
            dialogVisible: false,
            statStationId: '',
            props: {
                children: 'children',
                label: 'text',
                value: 'id'
            },

            formInline: {
                orgName: '',
                orgId: '',
                routeName: '',
                routeId: '',
                vehicleNo: ''
            },

            lists: [{
                vehicleNo: '',
                vin: '',
                routeName: '',
                orgName: '',
                inStationTime: '',
                outStationTime: ''
            }],

            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,

            routeOptions:[],
        }
    },
    components: {
        institutionsTree,
        xlinstitutionsTree
    },
    activated(){
        this.getRouteList()
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('checkForm')
            }
        }
    },
    methods: {
        getRouteList(){
            let url = '/baseinforoute/queryCurrentAuthorityRoute'
            this.$http.post(url).then(({detail})=>{
                this.routeOptions = detail
            })
        },
        // 初始化
        init(option) {
            this.statStationId = option
            this.getlist()
            this.dialogVisible = true
        },
        getlist() {
            let url = '/statstationdaily/queryStationDailyDetailBySId'
            let option = {
                ...this.formInline,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                statStationId: this.statStationId
            }
            this.$http.post(url, option).then(({ detail }) => {
                this.lists = detail.list
                this.total = detail.total
            })
        },

        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.formInline.orgId = ''
            this.formInline.routeId = ''
            this.oncheck()
        },
        // 选择机构树
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        // 上级机构查询
        addInstitutions(data) {
            // console.log(data)
            this.formInline.orgId = data.orgId
            this.formInline.orgName = data.name
        },
        // 选择线路树
        xlselectInstitutions() {
            this.$refs.xlinstitutions.init()
        },
        // 上级线路机构查询
        xladdInstitutions(data) {
            // console.log(data)
            this.formInline.routeId = data.id
            this.formInline.routeName = data.text
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        }
    }
}

</script>
<style lang="scss" scoped="details">
</style>
